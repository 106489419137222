.fullscreen-enabled {
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App {
  padding: 10px;
}

